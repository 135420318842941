import { Storage } from "aws-amplify";
// import { Currency } from "../types/Settings"
import { Line } from "../types/Invoice";

// function formatCurrency(cents, currency) {
//   if (currency.before) {
//     return currency.symbol + cents.toFixed(2);
//   }
//   return cents.toFixed(2) + currency.symbol;
// }

// function formatCurrency({
//   cents,
//   currency,
// }: {
//   cents: number,
//   currency: Currency,
// }) {
//   try {
//     if (currency.before) {
//       return currency.symbol + Number(cents).toFixed(2);
//     }
//     return Number(cents).toFixed(2) + currency.symbol;
//   } catch (e) {
//     console.error(e);
//     return "ERROR";
//   }
// }

// function calcTotal(lines) {
//   let total = 0;
//   lines.forEach((line) => {
//     total =
//       total +
//       line.cost * line.quantity +
//       ((line.cost * line.quantity) / 100) * line.vat;
//   });
//   return total;
// }

export function handleDownload(invoiceId:string) {
  // event.preventDefault();
  // API.download
  console.log(invoiceId);

  Storage.vault
    .get(invoiceId + ".pdf", {
      // download: true,
      level: "private",
      expires: 30,
    })
    .then((result) => {
      let ret = null;
      // let win =
      console.log(result);
      if (typeof result ==="string")
      try {
        ret = window.open(result, "_blank");
        // console.log(ret);
      } catch (error) {
        window.location.assign(result);
      } finally {
        if (!ret){
          window.location.assign(result);
        }
      }
      // , "_blank");
      // win.focus();
    })
    .catch((err) => console.error(err));
}

export function getLinesBrief({
  lines,
  total,
}: {
  lines: Line[];
  total: number;
}): string | false {
  let str = "";
  try {
    lines.forEach((line) => {
      str = str + line.description + ", ";
    });
    str+= total;
  } catch (error) {
    console.error(error);
    return false;
  }
  // console.log(str);
  return str;
}
