import React, { useRef, useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Form, Col, Image, Button, Container } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { s3UploadLogo } from "../libs/awsLib";
import config from "../config";
import "./Settings.css";
import { useTranslation } from "react-i18next";
import i18n from "../lang/i18n";

export default function Settings(props) {

  const file = useRef(null);
  const [settings, setSettings] = useState(null);
  const [name, setName] = useState("");
  const [vat, setVAT] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZIP] = useState("");
  const [footer, setFooter] = useState("");
  const [symbol, setSymbol] = useState("€");
  const [separator, setSeparator] = useState(",");
  const [before, setBefore] = useState(false);
  const [language, setLanguage] = useState("en");
  const [languageOptions, setLanguageOptions] = useState("en");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  // const languages = ["Français", "English"];
  function validateForm() {
    try {
      if (name.length === 0) return false;
      if (vat.length === 0) return false;
      if (address.length === 0) return false;
      if (country.length === 0) return false;
      if (city.length === 0) return false;
      if (zip.length === 0) return false;
      if (footer.length === 0) return false;
      if (symbol.length === 0) return false;
    } catch (e) {
      return false;
    }
    return true;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  useEffect(() => {
    function loadSettings() {
      return API.get("invoices", `/settings`);
    }

    async function onLoad() {
      setIsLoading(true);
      try {
        const settings = await loadSettings();
        const {
          name,
          vat,
          address,
          country,
          city,
          zip,
          footer,
          attachment,
          currency,
          language
        } = settings;

        if (attachment) {
          settings.attachmentURL = await Storage.vault.get(attachment);
        }

        setName(name);
        setVAT(vat);
        setAddress(address);
        setCountry(country);
        setCity(city);
        setZIP(zip);
        setFooter(footer);
        setLanguage(language);
        let items = [];
        // console.log(language);
        if (language === "fr"){
          items.push(<option value="fr"> Français</option>);
          items.push(<option value="en"> English</option>);
        }
        else{
          items.push(<option value="en"> English</option>);
          items.push(<option value="fr"> Français</option>);
        }
        setLanguageOptions(items);
        if (currency){
          setBefore(currency.before);
          setSymbol(currency.symbol);
          setSeparator(currency.separator);
        }
        setSettings(settings);
      } catch (e) {
        alert(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [props.match.params.id]);

  function saveSettings(settings) {
    return API.post("invoices", "/settings", {
      body: settings,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `${t("settings.alerts.fileMaxSize")} ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      let attachment;
      if (file.current) {
        attachment = await s3UploadLogo(file.current);
      } else {
        if (settings.attachment) attachment = settings.attachment;
      }

      // console.log(language);
      await saveSettings({
        name,
        vat,
        address,
        country,
        city,
        zip,
        footer,
        attachment,
        language,
        currency: {
          symbol: symbol,
          before: before,
          separator: separator
        },
      });
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }


  async function handleCheck(event) {
    if (before){
      setBefore(false);
    }
    else{
      setBefore(true);
    }
  }

  async function handleCancel(event) {
    event.preventDefault();

    const confirmed = window.confirm(t("generic.alerts.cancel"));

    if (!confirmed) {
      return;
    }

    props.history.push("/");
  }
  return (
    <Container className="Settings">
      <h2>{t("settings.labels.header")}</h2>
      {settings && (
        <Container className="shadow p-3 mb-5 bg-themed rounded form">
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <TransitionFormLabel delay={75}>
                  {t("settings.labels.name")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={75}
                  controlId="name"
                  type="text"
                  placeholder={t("settings.labels.name")}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <TransitionFormLabel delay={2 * 75}>
                  {t("settings.labels.vat")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={2 * 75}
                  controlId="vat"
                  type="text"
                  placeholder={t("settings.labels.vat")}
                  onChange={(e) => setVAT(e.target.value)}
                  value={vat}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <TransitionFormLabel delay={3 * 75}>
                  {t("settings.labels.address")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={3 * 75}
                  controlId="address"
                  type="text"
                  placeholder={t("settings.labels.address")}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <TransitionFormLabel delay={4 * 75}>
                  {t("settings.labels.city")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={4 * 75}
                  controlId="city"
                  type="text"
                  name="city"
                  placeholder={t("settings.labels.city")}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <TransitionFormLabel delay={5 * 75}>
                  {t("settings.labels.zip")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={5 * 75}
                  controlId="zip"
                  type="text"
                  placeholder={t("settings.labels.zip")}
                  onChange={(e) => setZIP(e.target.value)}
                  value={zip}
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <TransitionFormLabel delay={6 * 75}>
                  {t("settings.labels.country")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={6 * 75}
                  controlId="country"
                  type="text"
                  placeholder={t("settings.labels.country")}
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" xs={12}>
                <TransitionFormLabel delay={7 * 75}>
                  {t("settings.labels.footer")}
                </TransitionFormLabel>
                <TransitionFormControl
                  as="textarea"
                  style={{ fontSize: 2 }}
                  rows="3"
                  delay={7 * 75}
                  controlId="footer"
                  type="text"
                  placeholder={t("settings.labels.footer")}
                  onChange={(e) => setFooter(e.target.value)}
                  value={footer}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="8" xs="12">
                <TransitionFormLabel delay={8 * 75}>
                  {t("settings.labels.language")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={8 * 75}
                  custom
                  as="select"
                  controlId="language"
                  defaultValue={language}
                  onChange={(e) => {
                    // console.log(JSON.parse(e.target.value).name);
                    // console.log(JSON.parse(e.target.value).clientId);
                    // console.log(e.target.value);
                    if (e.target.value === "fr") {
                      setLanguage("fr");
                      i18n.changeLanguage("fr");
                    } else {
                      setLanguage("en");
                      i18n.changeLanguage("en");
                    }
                  }}
                >
                  {/* <option value="en">English</option>
                  <option value="fr">Français</option> */}
                  {languageOptions}
                  {/* {clientsSelect} */}
                </TransitionFormControl>
              </Form.Group>
              <Form.Group as={Col} md="1" xs="4">
                <TransitionFormLabel delay={9 * 75}>
                  {t("settings.labels.currency")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={9 * 75}
                  controlId="symbol"
                  type="text"
                  placeholder="€,$,£,..."
                  onChange={(e) => setSymbol(e.target.value)}
                  value={symbol}
                  maxLength="1"
                />
              </Form.Group>
              <Form.Group as={Col} md="1" xs="4">
                <TransitionFormLabel delay={9 * 75}>
                  {t("settings.labels.separator")}
                </TransitionFormLabel>
                <TransitionFormControl
                  delay={9 * 75}
                  controlId="separator"
                  type="text"
                  placeholder=".,"
                  onChange={(e) => setSeparator(e.target.value)}
                  value={separator}
                  maxLength="1"
                />
              </Form.Group>
              <Form.Group as={Col} md="1" xs="4" className="CheckBoxes">
                <Col sm={10}>
                  <TransitionGroup>
                    <CSSTransition
                      timeout={400}
                      classNames="control-form-transition"
                      style={{
                        transition: `opacity ${10 * 75}ms, transform ${
                          10 * 75
                        }ms`,
                      }}
                      unmountOnExit
                      appear
                    >
                      <Form.Check
                        type="radio"
                        label={"123" + separator + "45" + symbol}
                        name="formHorizontalRadios"
                        onChange={handleCheck}
                        checked={!before}
                      />
                    </CSSTransition>
                    <CSSTransition
                      timeout={400}
                      classNames="control-form-transition"
                      style={{
                        transition: `opacity ${11 * 75}ms, transform ${
                          11 * 75
                        }ms`,
                      }}
                      unmountOnExit
                      appear
                    >
                      <Form.Check
                        type="radio"
                        label={symbol + "123" + separator + "45"}
                        name="formHorizontalRadios"
                        onChange={handleCheck}
                        checked={before}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                </Col>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Group controlId="file">
                  {!settings.attachment}
                  <TransitionFormLabel delay={11 * 75}>
                    {t("settings.labels.logo.update")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={11 * 75}
                    onChange={handleFileChange}
                    type="file"
                    accept=".png"
                  />
                </Form.Group>
              </Form.Group>
            </Form.Row>
            {settings.attachment && (
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <TransitionFormLabel delay={12 * 75}>
                    {t("settings.labels.logo.actual")}
                  </TransitionFormLabel>
                  <Form.Group controlId="file">
                    <TransitionGroup>
                      <CSSTransition
                        timeout={400}
                        classNames="control-form-transition"
                        style={{
                          transition: `opacity ${12 * 75}ms, transform ${
                            12 * 75
                          }ms`,
                        }}
                        unmountOnExit
                        appear
                      >
                        <Image src={settings.attachmentURL} thumbnail />
                      </CSSTransition>
                    </TransitionGroup>
                  </Form.Group>
                </Form.Group>
              </Form.Row>
            )}
            <LoaderButton
              type="submit"
              variant="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              {t("generic.buttons.save")}
            </LoaderButton>{" "}
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleCancel}
            >
              {t("generic.buttons.cancel")}
            </Button>
          </Form>
        </Container>
      )}
    </Container>
  );
}
