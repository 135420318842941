import React, { useState } from "react";
import { Form, Col, Container, InputGroup, Button, FormControl, Spinner } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./NewClient.css";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";

export default function NewClient(props) {
  const [name, setName] = useState("");
  const [vat, setVAT] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZIP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVat, setIsLoadingVat] = useState(false);
  const { t } = useTranslation();

  function validateVat() {
    if (vat.length === 0) return false;
    return true;
  }

  function validateForm() {
    try {
      if (name.length === 0) return false;
      if (vat.length === 0) return false;
      if (address.length === 0) return false;
      if (country.length === 0) return false;
      if (city.length === 0) return false;
      if (zip.length === 0) return false;
    } catch (error) {
      return false;
    }
    return true;
  }

  function getClientInfoFromVat(countryCode, vatNumber) {
    return API.get(
      "invoices",
      `/client_info_from_vat/${countryCode}${vatNumber}`
    );
  }

  function flipFirstLastName(str) {
    if (str.includes(",")) {
      const splits = str.split(",");
      return splits[1].substring(1) + " " + splits[0];
    } else {
      return str;
    }
  }

  async function handleSearchClientInfoFromVat(event) {
    try {
      // ? WIP
      setIsLoadingVat(true);
      let countryCode = vat.substring(0, 2).toUpperCase();
      let vatNumber = vat.substring(2, vat.length);

      console.log(countryCode);
      console.log(vatNumber);

      const clientInfoFromVat = await getClientInfoFromVat(countryCode, vatNumber);
      console.log(clientInfoFromVat);
      

      // ! Could be improved
      if (countryCode === "BE") {
        setName(flipFirstLastName(clientInfoFromVat.name));
        // ? received format: "rue de xxx number, zip city"
        setAddress(clientInfoFromVat.address.split(', ')[0]);
        let tmp = clientInfoFromVat.address.split(", ")[1].split(" ")[0];
        setZIP(tmp);
        setCity(clientInfoFromVat.address.split(tmp)[1]);
      }else{
        setName(clientInfoFromVat.name);
        setAddress(clientInfoFromVat.address);
        setZIP(clientInfoFromVat.address);
        setCity(clientInfoFromVat.address);
      }
      setCountry(clientInfoFromVat.country);

    } catch (e) {
      setName("");
      setAddress("");
      setCountry("");
      console.error(e);
      alert(t("client.new.alerts.notFoundFromVAT"));
    }
    setIsLoadingVat(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const ret = await createClient({ name, vat, address, country, city, zip });
      console.log(ret);
      props.history.push("/clients");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createClient(client) {
    return API.post("invoices", "/clients", {
      body: client,
    });
  }

  return (
    <Container className="NewClient">
      <h2>{t("client.new.labels.header")}</h2>
      <Container className="shadow p-3 mb-5 bg-themed rounded form">
        <Form horizontal>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <TransitionFormLabel delay={2 * 75}>
                {t("client.new.labels.vat")}
              </TransitionFormLabel>
              <TransitionGroup>
                <CSSTransition
                  timeout={400}
                  classNames="control-form-transition"
                  style={{
                    transition: `opacity ${2 * 75}ms, transform ${2 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <InputGroup>
                    <FormControl
                      controlId="vat"
                      type="text"
                      placeholder={t("client.new.labels.vat")}
                      onChange={(e) => setVAT(e.target.value.toUpperCase())}
                      value={vat}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        disabled={!validateVat()}
                        onClick={handleSearchClientInfoFromVat}
                      >
                        {!isLoadingVat ? t("client.new.buttons.search") : ""}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          hidden={!isLoadingVat}
                        ></Spinner>
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </CSSTransition>
              </TransitionGroup>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <TransitionFormLabel delay={75}>
                {t("client.new.labels.name")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={75}
                controlId="name"
                type="text"
                placeholder={t("client.new.labels.name")}
                componentClass="input"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="12">
              <TransitionFormLabel delay={3 * 75}>
                {t("client.new.labels.address")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={3 * 75}
                controlId="address"
                type="text"
                placeholder={t("client.new.labels.address")}
                componentClass="input"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="4">
              <TransitionFormLabel delay={4 * 75}>
                {t("client.new.labels.city")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={4 * 75}
                controlId="city"
                type="text"
                name="city"
                placeholder={t("client.new.labels.city")}
                onChange={(e) => setCity(e.target.value)}
                value={city}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <TransitionFormLabel delay={5 * 75}>
                {t("client.new.labels.zip")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={5 * 75}
                controlId="zip"
                type="text"
                placeholder={t("client.new.labels.zip")}
                onChange={(e) => setZIP(e.target.value)}
                value={zip}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <TransitionFormLabel delay={6 * 75}>
                {t("client.new.labels.country")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={6 * 75}
                controlId="country"
                type="text"
                placeholder={t("client.new.labels.country")}
                componentClass="input"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              />
            </Form.Group>
          </Form.Row>

          <LoaderButton
            type="submit"
            variant="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
            onClick={handleSubmit}
          >
            {t("generic.buttons.create")}
          </LoaderButton>
        </Form>
      </Container>
    </Container>
  );
}
