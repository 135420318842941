import { Storage } from "aws-amplify";


export async function s3Upload(file: {
  name: string;
  type: string;
}): Promise<string | false> {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  console.log(stored);

  if (stored) {
    // @ts-ignore
    return stored.key;
  }
  return false;
}

export async function s3UploadLogo(file: {
  name: string;
  type: string;
}): Promise<string | false> {
  const filename = `logo.${file.name.substr(file.name.lastIndexOf('.') + 1)}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  console.log(stored);

  if (stored) {
    // @ts-ignore
    return stored.key;
  }
  return false;
}