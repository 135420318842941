import React, { useState, useEffect } from "react";
import "./ClientsHome.css";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import {
  ListGroup,
  Spinner,
  Container,
  Card,
  Button,
  Form,
  Col,
  Fade,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import "./ClientsHome.css";
import * as clientsLib from "../libs/clientsLib";
import renderEmptyListAlert from "../libs/emptyListLib";
import LoaderButton from "../components/LoaderButton";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";

export default function ClientsHome(props) {
  const [clients, setClients] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const clients = await loadClients();
        setClients(clients);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadClients() {
    return API.get("invoices", "/clients");
  }

  function loadClientsFiltered(name) {
    return API.get("invoices", `/clients/filtered/${encodeURI(name)}`);
  }

  async function handleFilter(event){
    event.preventDefault();
    setIsSearching(true);
    const ret = await loadClientsFiltered(filterName);
    // console.log(ret);
    setClients(ret);
    setIsSearching(false);
  }

  async function handleButtonFilter() {
    if (isFiltering) {
      setIsFiltering(false);
      const clients = await loadClients();
      setClients(clients);
    } else setIsFiltering(true);
  }

  function validateFilter(){
    if (filterName.length > 0) return true;
    return false;
  }

  function renderClientsList(clients) {
    if (clients.length === 0) {
      return (
        <>
          {renderEmptyListAlert({
            header: t("client.home.empty.header"),
            text: t("client.home.empty.text"),
          })}
        </>
      );
    }
    return (
      <TransitionGroup>
        {[{}].concat(clients).map((client, i) =>
          i !== 0 ? (
            <CSSTransition
              //in={this.state.showList}
              timeout={400}
              classNames="list-transition"
              style={{
                transition: `opacity ${i * 75}ms, transform ${i * 75}ms`,
              }}
              unmountOnExit
              appear
              //onEntered={this.listSwitch}
              //onExit={this.listSwitch}
            >
              <div className="list-body">
                <ul className="list">
                  <li>
                    {/* <Container className="ListClients"> */}
                      <Card className="ListClients shadow">
                        <Card.Header>
                          {client.vat}
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>{client.name}</Card.Title>
                          <Card.Text>
                            {clientsLib.getClientInfoBrief(client)}
                          </Card.Text>
                          <LinkContainer to={"/clients/" + client.clientId}>
                            <Button variant="primary">{t("generic.buttons.update")}</Button>
                          </LinkContainer>

                          {/* <Button variant="primary" href={}>
                          Update
                        </Button> */}
                        </Card.Body>
                      </Card>
                    {/* </Container> */}
                  </li>
                </ul>
              </div>
            </CSSTransition>
          ) : (
            <Container></Container>
          )
        )}
      </TransitionGroup>
    );
  }

  function renderLander() {
    return (
      <Container className="lander">
        <h1>Invoices builder</h1>
        <p>A simple invoice builder app</p>
      </Container>
    );
  }

  function renderClients() {
    return (
      <Container className="clients">
        <Form.Row>
          <Form.Group as={Col} lg="10" md="9" xs="8">
            <h2 className="header">{t("client.home.labels.header")}</h2>
          </Form.Group>
          <Form.Group as={Col} lg="2" md="3" xs="4">
            <Dropdown alignRight>
              <Dropdown.Toggle
                className="float-right"
                variant="outline-primary"
                id="dropdown-basic"
              >
                <i className="fa fa-plus"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <LinkContainer key="new" to="/clients/new">
                  <Dropdown.Item>
                    {t("client.home.buttons.new.client")}
                  </Dropdown.Item>
                </LinkContainer>
                <LinkContainer key="new" to="/clients/new/multiple">
                  <Dropdown.Item>
                    {t("client.home.buttons.new.clients")}
                  </Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown>
            {/* <LinkContainer key="new" to="/clients/new">
              <LoaderButton
                isLoading={isLoading}
                className="float-right"
                variant="outline-primary"
              >
                <i className="fa fa-plus"></i>
              </LoaderButton>
            </LinkContainer> */}
            <LoaderButton
              style={{ marginRight: ".25em" }}
              className="float-right"
              onClick={handleButtonFilter}
              variant="outline-primary"
            >
              <i className="fa fa-filter"></i>
            </LoaderButton>{" "}
          </Form.Group>
        </Form.Row>
        <Form onSubmit={handleFilter}>
          <Form.Row>
            <Fade in={isFiltering} unmountOnExit="false" mountOnEnter="false">
              <Form.Group as={Col} md="12">
                <InputGroup>
                  <Form.Control
                    controlId="clientName"
                    type="text"
                    placeholder={t("client.home.placeholders.filter")}
                    onChange={(e) => setFilterName(e.target.value)}
                    value={filterName}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="primary"
                      disabled={!validateFilter()}
                      // onClick={handleFilter}
                      type="submit"
                    >
                      {!isSearching ? t("client.home.buttons.filter") : ""}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        hidden={!isSearching}
                      ></Spinner>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              {/* <AlertDismissible
              header={alertHeader}
              text={alertText}
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
            /> */}
            </Fade>
          </Form.Row>
        </Form>
        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        <ListGroup variant="flush">
          {!isLoading && renderClientsList(clients)}
        </ListGroup>
      </Container>
    );
  }

  return (
    <Container className="ClientsHome">
      {props.isAuthenticated ? renderClients() : renderLander()}
    </Container>
  );
}
