import React from "react";
import { Form } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./TransitionFormControl.css";


type TransitionFormcontrolProps = React.FC<{
  delay:number;  
}>

const TransitionFormcontrol: TransitionFormcontrolProps = ({
  delay = 1000,
  ...props
}) => {
  return (
    <>
      <TransitionGroup>
        <CSSTransition
          timeout={400}
          classNames="control-form-transition"
          style={{
            transition: `opacity ${delay}ms, transform ${delay}ms`,
          }}
          unmountOnExit
          appear
        >
          <Form.Control {...props}>{props.children}</Form.Control>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default TransitionFormcontrol;