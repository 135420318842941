import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import resources from "./resources";
import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: "lng",
    },
  });
export default i18n;