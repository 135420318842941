import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Col, Form, Container, Card, Button, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";
import "./Clients.css";
import * as invoiceLib from "../libs/invoicesLib";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";

export default function Clients(props) {
  const [client, setClient] = useState(null);
  const [name, setName] = useState("");
  const [vat, setVAT] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZIP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [invoices, setInvoices] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    
    function loadClient() {;
      return API.get("invoices", `/clients/${props.match.params.id}`);
    }


    async function onLoad() {
      try {
        setIsLoading(true);
        const client = await loadClient();

        const { name, vat, address, country, city, zip } = client;

        setName(name);
        setVAT(vat);
        setAddress(address);
        setCountry(country);
        setCity(city);
        setZIP(zip);
        setClient(client);

        const invoices = await getInvoices();
        setInvoices(invoices);
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function getInvoices() {
    return API.get(
      "invoices",
      `/all_invoices_from_client/${props.match.params.id}`
    );
  }

  function validateForm() {
    try {
      if (name.length === 0) return false;
      if (vat.length === 0) return false;
      if (address.length === 0) return false;
      if (country.length === 0) return false;
      if (city.length === 0) return false;
      if (zip.length === 0) return false;
    } catch (TypeError) {
      return false;
    }
    return true;
  }

  function saveClient(client) {
    return API.put("invoices", `/clients/${props.match.params.id}`, {
      body: client,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveClient({
        name,
        vat,
        address,
        country,
        city,
        zip,
      });
      props.history.push("/clients");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function deleteClient() {
    return API.del("invoices", `/clients/${props.match.params.id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();
    const confirmed = window.confirm(
      t("client.update.alerts.delete.client")
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteClient();
      props.history.push("/clients");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }
  }

  async function handleDeleteInvoice(event, invoiceId) {
    event.preventDefault();

    const confirmed = window.confirm(t("client.update.alerts.delete.invoice"));

    if (!confirmed) {
      return;
    }
    setIsDeleting(true);

    try {
      await API.del("invoices", `/invoices/${invoiceId}`);
      const invoices = await getInvoices();
      setInvoices(invoices);
    } catch (e) {
      alert(e);
    }
    setIsDeleting(false);
  }

  function renderInvoicesList(invoices) {
    // console.log(invoices);

    return (
      <TransitionGroup>
        {[{}].concat(invoices).map((invoice, i) =>
          i !== 0 ? (
            <CSSTransition
              timeout={400}
              classNames="list-transition"
              style={{
                transition: `opacity ${i * 75}ms, transform ${i * 75}ms`,
              }}
              unmountOnExit
              appear
            >
              <Card className="listInvoices shadow mb-3 bg-themed">
                <Card.Header>
                  {t("invoice.home.labels.number") + invoice.invoiceNumber}
                </Card.Header>
                <Card.Body>
                  <Card.Title>
                    {t("invoice.home.labels.to") +
                      invoice.client.name +
                      t("invoice.home.labels.created") +
                      new Date(invoice.createdAt).toLocaleString()}
                  </Card.Title>
                  <Card.Text>
                    {/* {JSON.stringify(invoice.lines)} */}
                    {invoiceLib.getLinesBrief({
                      lines: invoice.lines,
                      total: invoice.total,
                    })}
                  </Card.Text>
                  <LinkContainer to={"/invoices/" + invoice.invoiceId}>
                    <Button variant="primary">
                      {t("generic.buttons.update")}
                    </Button>
                  </LinkContainer>{" "}
                  <LoaderButton
                    isLoading={isDeleting}
                    variant="danger"
                    onClick={(e) => handleDeleteInvoice(e, invoice.invoiceId)}
                  >
                    {t("generic.buttons.delete")}
                  </LoaderButton>
                  <LoaderButton
                    variant="link"
                    onClick={(e) =>
                      invoiceLib.handleDownload(invoice.invoiceId)
                    }
                  >
                    {t("generic.buttons.download")}
                  </LoaderButton>
                </Card.Body>
              </Card>
            </CSSTransition>
          ) : (
            <Container></Container>
          )
        )}
      </TransitionGroup>
    );
  }

  function renderInvoices() {
    return (
      <Container className="ClientInvoices">
        <Form.Row>
          <Form.Group as={Col} lg="8" md="8" xs="10">
            <h2 className="header">{t("client.update.labels.invoices.header")}</h2>
          </Form.Group>
          <Form.Group as={Col} lg="4" md="4" xs="2">
            <LinkContainer key="new" to="/invoices/new">
              <LoaderButton className="float-right" isLoading={isLoading}>
                <i className="fa fa-plus"></i>
              </LoaderButton>
            </LinkContainer>
          </Form.Group>
        </Form.Row>

        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        {!isLoading && renderInvoicesList(invoices)}
      </Container>
    );
  }

  return (
    <Container className="Clients">
      <h2>{t("client.update.labels.header")}</h2>
      {client && (
        <>
          <Container className="shadow p-3 mb-5 bg-themed rounded form">
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <TransitionFormLabel delay={2 * 75}>
                    {t("client.update.labels.vat")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={2 * 75}
                    controlId="vat"
                    type="text"
                    placeholder={t("client.update.labels.vat")}
                    onChange={(e) => setVAT(e.target.value)}
                    value={vat}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <TransitionFormLabel delay={75}>
                    {t("client.update.labels.name")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={75}
                    controlId="name"
                    type="text"
                    placeholder={t("client.update.labels.name")}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <TransitionFormLabel delay={3 * 75}>
                    {t("client.update.labels.address")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={3 * 75}
                    controlId="address"
                    type="text"
                    placeholder={t("client.update.labels.address")}
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <TransitionFormLabel delay={4 * 75}>
                    {t("client.update.labels.city")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={4 * 75}
                    controlId="city"
                    type="text"
                    name={t("client.update.labels.city")}
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <TransitionFormLabel delay={5 * 75}>
                    {t("client.update.labels.zip")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={5 * 75}
                    controlId="zip"
                    type="text"
                    placeholder={t("client.update.labels.zip")}
                    onChange={(e) => setZIP(e.target.value)}
                    value={zip}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <TransitionFormLabel delay={6 * 75}>
                    {t("client.update.labels.country")}
                  </TransitionFormLabel>
                  <TransitionFormControl
                    delay={6 * 75}
                    controlId="country"
                    type="text"
                    placeholder={t("client.update.labels.country")}
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                  />
                </Form.Group>
              </Form.Row>
              <LoaderButton
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                {t("generic.buttons.update")}
              </LoaderButton>{" "}
              <LoaderButton
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                {t("generic.buttons.delete")}
              </LoaderButton>
            </Form>
          </Container>
          {renderInvoices()}
        </>
      )}
    </Container>
  );
}
