import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { Form, Fade, Container } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AlertDismissible from "../components/AlertDismissible";
import { useFormFields } from "../libs/hooksLib";
import i18n from "../lang/i18n";

import "./Login.css";

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [forgotVue, setForgotVue] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertVariant, setAlertVariant] = useState("");

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  const [newFields, handleNewFieldChange] = useFormFields({
    email: fields.email,
    code: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateFormForgot() {
    console.log(fields.email);

    if (newFields.code.length === 0) return false;
    if (newFields.newPassword.length === 0) return false;
    if (newFields.newPasswordConfirm.length === 0) return false;
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      const settings = await API.get("invoices", `/settings`);
      const {
        language
      } = settings;
      // console.log(settings);
      // console.log(`language: ${language}`);
      i18n.changeLanguage(language);
      props.userHasAuthenticated(true);
    } catch (e) {
      setAlertHeader("Oops, an error happened");
      setAlertText("You probably mistyped your credentials, please try again!");
      setAlertVariant("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  }

  async function handleSubmitForgot(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        newFields.code,
        newFields.newPassword
      )
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      await Auth.signIn(fields.email, newFields.newPassword);
      props.userHasAuthenticated(true);
      // props.history.push("/");
    } catch (err) {
      setAlertHeader("Oops, an error happened");
      setAlertText(err.message);
      setAlertVariant("danger");
      setShowAlert(true);
      setIsLoading(false);
      console.error(err);
    }
  }

  async function handleForgotPassword(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.forgotPassword(fields.email)
        .then((data) => {
          console.log(data);

          setForgotVue(true);
        })
        .catch((err) => {
          // Alert("An error happened!", "The email can not be empty. please fill your email address.", "danger")
          setAlertHeader("Oops, an error happened");
          setAlertText(err.message);
          setAlertVariant("danger");
          setShowAlert(true);
          console.log(err);
        });
    } catch (e) {
      setAlertHeader("Oops, an error happened");
      setAlertText(e.message);
      setAlertVariant("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  function renderLogin() {
    return (
      <Container>
        <Container>
          <Fade in={showAlert} unmountOnExit="true" mountOnEnter="true">
            <AlertDismissible
              header={alertHeader}
              text={alertText}
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
            />
          </Fade>
        </Container>
        <Container className="Login">
          <form onSubmit={handleSubmit}>
            <Form.Group controlId="email" bsSize="large">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                placeholder="Email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="password" bsSize="large">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={fields.password}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <LoaderButton
              block
              variant="link"
              onClick={handleForgotPassword}
              isLoading={isLoading}
            >
              Forgot password ?
            </LoaderButton>
            <LoaderButton
              block
              type="submit"
              bsSize="large"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Login
            </LoaderButton>
          </form>
        </Container>
      </Container>
    );
  }

  function renderForgotPassword() {
    return (
      <Container>
        <Fade in={showAlert} unmountOnExit="true" mountOnEnter="true">
          <AlertDismissible
            header={alertHeader}
            text={alertText}
            variant={alertVariant}
            onClose={() => setShowAlert(false)}
          />
        </Fade>
        <Container className="Login">
          <form onSubmit={handleSubmitForgot}>
            <Form.Group controlId="email" bsSize="large">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                placeholder="Email"
                value={fields.email}
                disabled="true"
              // onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="code" bsSize="large">
              <Form.Label>Confirmation code</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder="Code received via email"
                value={newFields.code}
                onChange={handleNewFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="newPassword" bsSize="large">
              <Form.Label>New password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={newFields.newPassword}
                onChange={handleNewFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="newPasswordConfirm" bsSize="large">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={newFields.newPasswordConfirm}
                onChange={handleNewFieldChange}
              />
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              bsSize="large"
              isLoading={isLoading}
              disabled={!validateFormForgot()}
            >
              Update
            </LoaderButton>
          </form>
        </Container>
      </Container>
    );
  }

  return (
    <Container>
      {/* {renderLogin()} */}
      {forgotVue === false ? renderLogin() : renderForgotPassword()}
    </Container>
  );
}
