import React, { useState, useEffect } from "react";
import { FaCog } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import Container from "react-bootstrap/Container";
import { IndexLinkContainer } from "react-router-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "./lang/i18n";
import { useTranslation } from "react-i18next";
// import loadLanguage from "./libs/languagesLib";
// import "@forevolve/bootstrap-dark/dist/css/bootstrap-dark.css";

const App = (props) => {
  const { t } = useTranslation();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // const Home = () => <span>Home</span>;

  // const About = () => <span>About</span>;

  // const Users = () => <span>Users</span>;

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      // loadLanguage();
    } catch (e) {
      if (e !== "No current user") {
        // alert(e);
        console.log(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/login");
  }

  
  return (
    !isAuthenticating && (
      // <Container className="p-3">
      <Container className="App">
        {/* <Navbar fixed='top' fluid collapseOnSelect  bg="light" expand="lg" */}
        <Navbar
          fixed="top"
          collapseOnSelect
          bg="primary"
          variant="dark"
          expand="sm"
          // style={{marginTop: "-1000px !important"}} inverse
          className="App.nav-bar justify-content-between"
        >
          <IndexLinkContainer to="/" activeClassName="active">
            <Navbar.Brand>
              <img
                alt=""
                src="/logo.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              Invoice builder 1.0
            </Navbar.Brand>
          </IndexLinkContainer>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {isAuthenticated ? (
                <>
                  <IndexLinkContainer to="/" activeClassName="active">
                    <Nav.Link>{t("nav.invoices")}</Nav.Link>
                  </IndexLinkContainer>
                  <IndexLinkContainer to="/clients" activeClassName="active">
                    <Nav.Link>{t("nav.clients")}</Nav.Link>
                  </IndexLinkContainer>
                  <IndexLinkContainer to="/settings" activeClassName="active">
                    <Nav.Link>{t("nav.settings")}</Nav.Link>
                  </IndexLinkContainer>
                </>
              ) : null}
            </Nav>
            <Nav className="ml-auto">
              {isAuthenticated ? (
                <>
                  {/* <IndexLinkContainer to="/clients">
                    <Nav.Link>Clients</Nav.Link>
                  </IndexLinkContainer>
                  <IndexLinkContainer to="/settings" activeClassName="active">
                    <Nav.Link>Settings</Nav.Link>
                  </IndexLinkContainer> */}

                  <NavDropdown title={<FaCog />} alignRight>
                    <NavDropdown.Item
                      onClick={(e) => {
                        i18n.changeLanguage("en");
                      }}
                    >
                      English
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={(e) => {
                        i18n.changeLanguage("fr");
                      }}
                    >
                      Français
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>
                      {t("nav.logout")}
                    </NavDropdown.Item>

                    <IndexLinkContainer
                      to="/updatePassword"
                      activeClassName="active"
                    >
                      <NavDropdown.Item>
                        {t("nav.updatePassword")}
                      </NavDropdown.Item>
                    </IndexLinkContainer>
                    {/* </NavDropdown.Item> */}
                    {/* <NavDropdown.Item onClick={handleUpdatePassword}>
                      Update password
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </>
              ) : (
                <IndexLinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </IndexLinkContainer>
                // <Nav.Link eventKey={2} href="/login">
                //   Login
                // </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
      </Container>
    )
  );
};
export default withRouter(App);
