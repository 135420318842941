import React, { useState, Fragment } from "react";
import {
  Form,
  Col,
  Container,
  FormControl,
  ProgressBar,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./NewClients.css";
import "./lists.css";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";

export default function NewClient(props) {
  // const [name, setName] = useState("");
  const [vat, setVAT] = useState("");
  // const [address, setAddress] = useState("");
  // const [country, setCountry] = useState("");
  // const [city, setCity] = useState("");
  // const [zip, setZIP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVat, setIsLoadingVat] = useState(false);
  const [progress, setProgress] = useState(0);
  const [clientsToCreate, setClientsToCreate] = useState([]);

  const { t } = useTranslation();

  function validateVat() {
    if (vat.length === 0) return false;
    return true;
  }

  function getClientInfoFromVat(countryCode, vatNumber) {
    return API.get(
      "invoices",
      `/client_info_from_vat/${countryCode}${vatNumber}`
    );
  }

  function flipFirstLastName(str) {
    if (str.includes(",")) {
      const splits = str.split(",");
      return splits[1].substring(1) + " " + splits[0];
    } else {
      return str;
    }
  }

  async function incrementProgress(progress_tmp) {
    // progress += progress_tmp * 3;
    setProgress(progress_tmp)
    console.log(`progress_tmp ${progress_tmp}`);
    //     await new Promise((r) => setTimeout(r, 2000));

    console.log(`progress ${progress}`);

  }

  async function handleSearchClientInfoFromVat(event) {
    event.preventDefault();
    setIsLoadingVat(true);
    let vats = [...new Set(vat.split("\n"))];
    let clients_tmp = [];
    let failed_str = "";
    let progress_tmp = 0;
    const promises = vats.map(async vat_tmp => {
        
      try {
        let countryCode = vat_tmp.substring(0, 2).toUpperCase();
        let vatNumber = vat_tmp.substring(2, vat_tmp.length);
        // await new Promise((r) =>
        //   setTimeout(
        //     r,
        //     2000 + Math.floor(Math.random() * Math.floor(3000))
        //   )
        // );
        // console.log(vat_tmp);

        const clientInfoFromVat = await getClientInfoFromVat(
          countryCode,
          vatNumber
        );

        // console.log(clientInfoFromVat);
        let client_tmp = {};
        client_tmp.vat = vat_tmp;

        // ! Could be improved
        if (countryCode === "BE") {
          client_tmp.name = flipFirstLastName(clientInfoFromVat.name);

          // ? received format: "rue de xxx number, zip city"
          client_tmp.address = clientInfoFromVat.address.split(", ")[0];
          let tmp = clientInfoFromVat.address.split(", ")[1].split(" ")[0];
          client_tmp.zip = tmp;

          client_tmp.city = clientInfoFromVat.address.split(tmp)[1];
        } else {
          client_tmp.name = clientInfoFromVat.name;
          client_tmp.address = clientInfoFromVat.address;
          client_tmp.zip = clientInfoFromVat.address;
          client_tmp.city = clientInfoFromVat.address;
        }
        client_tmp.country = clientInfoFromVat.country;
        clients_tmp.push(client_tmp);
        handleAddFields(clients_tmp);
        // progress_tmp += 100 / vats.length;
        // incrementProgress(progress_tmp);
      } catch (e) {
        console.error(e);
        failed_str += vat_tmp + ",\n";
        // alert(t("client.new.alerts.notFoundFromVAT") + );
      }
      progress_tmp += 100 / vats.length;
      incrementProgress(progress_tmp);
      
    });

    await Promise.all(promises);
    // console.log("Done");
    setIsLoadingVat(false);
    if (failed_str.length > 0) {
      failed_str = failed_str.substring(0, failed_str.length - 2);
      alert(t("client.new_multiple.alerts.notFoundFromVAT") + failed_str);
    }
    setClientsToCreate(clients_tmp);
  }
  async function handleAddFields(clients_temp) {
    const values = [];
    values.splice(0, 1);
    // console.log(values);
    clients_temp.forEach(async (client_tmp) => {
      // console.log(client_tmp);
      values.push({
        address: client_tmp.address,
        city: client_tmp.city,
        country: client_tmp.country,
        name: client_tmp.name,
        vat: client_tmp.vat,
        zip: client_tmp.zip,
      });
      // console.log(clientsToCreate);
    });
    // console.log(values);
    setClientsToCreate(values);
  }

  const handleRemoveFields = (index) => {
    const values = [...clientsToCreate];
    values.splice(index, 1);
    setClientsToCreate(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...clientsToCreate];

    try {
      if (event.target.name === "vat") {
        values[index].vat = event.target.value;
      } else if (event.target.name === "name") {
        values[index].name = event.target.value;
      } else if (event.target.name === "address") {
        values[index].address = event.target.value;
      } else if (event.target.name === "city") {
        values[index].city = event.target.value;
      } else if (event.target.name === "country") {
        values[index].country = event.target.value;
      } else {
        values[index].zip = event.target.value;
      }
      setClientsToCreate(values);
    } catch (TypeError) {
      console.error("error");
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    let clientsFailed = [];

    const promises = clientsToCreate.map(async clientToCreate => {
      try {
        const ret = await createClient(clientToCreate);
        // console.log(ret);
      } catch (e) {
        clientsFailed.push(clientToCreate);
        // alert(e);
      }
    });
    await Promise.all(promises);
    setIsLoading(false);
    console.log("Done");
    if (clientsFailed.length > 0) {
      let failed_str = "";
      const promises = clientsFailed.map(async clientFailed => {
        failed_str += clientFailed.vat + ":" + clientFailed.name + ",\n";
      });
      await Promise.all(promises);
      failed_str = failed_str.substring(0, failed_str.length - 2);
      alert(
        t("client.new_multiple.alerts.createFailed.part1") +
          failed_str +
          "\n" +
          clientsToCreate.filter((x) => !clientsFailed.includes(x)).length +
          t("client.new_multiple.alerts.createFailed.part2")
      );
      setClientsToCreate(clientsFailed);
    } else {
      props.history.push("/clients");
    }
  }

  async function handleCreateSingleClient(index) {
    // event.preventDefault();

    setIsLoading(true);

    let clientToCreate;
    try {
      const values = [...clientsToCreate];
      let client = values.splice(index, 1);
      clientToCreate = client[0];

      // console.log(client[0]);
      await createClient(clientToCreate);
      // console.log(ret);
      setClientsToCreate(values);
      // props.history.push("/clients");
    } catch (e) {
      // alert(e);
      alert(
        t("client.new_multiple.alerts.createFailed.part1") + clientToCreate.name
      );
    }
    setIsLoading(false);
  }

  function createClient(client) {
    return API.post("invoices", "/clients", {
      body: client,
    });
  }

  return (
    <>
      <Container className="NewClients">
        <Form.Row>
          <Form.Group as={Col} lg="10" md="9" xs="8">
            <h2>{t("client.new_multiple.labels.header")}</h2>
          </Form.Group>
          <Form.Group as={Col} lg="2" md="3" xs="4">
            <LoaderButton
              // type="submit"
              className="float-right"
              variant="primary"
              isLoading={isLoading}
              disabled={!clientsToCreate.length > 0 || isLoadingVat}
              onClick={handleSubmit}
            >
              {t("client.new_multiple.buttons.createAll")}
            </LoaderButton>
          </Form.Group>
        </Form.Row>
        <Container className="shadow p-3 mb-5 bg-themed rounded form">
          <Form horizontal>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <TransitionFormLabel delay={2 * 75}>
                  {t("client.new_multiple.labels.vat")}
                </TransitionFormLabel>
                <TransitionGroup>
                  <CSSTransition
                    timeout={400}
                    classNames="control-form-transition"
                    style={{
                      transition: `opacity ${2 * 75}ms, transform ${2 * 75}ms`,
                    }}
                    unmountOnExit
                    appear
                  >
                    {/* <InputGroup> */}
                    <FormControl
                      as="textarea"
                      style={{ fontSize: 2 }}
                      rows="3"
                      controlId="vat"
                      type="text"
                      placeholder={t("client.new_multiple.placeholders.vat")}
                      onChange={(e) =>
                        setVAT(
                          e.target.value
                            .toUpperCase()
                            .replace(",", "\n")
                            .replace(".", "\n")
                            .replace(";", "\n")
                            .replace(" ", "")
                        )
                      }
                      value={vat}
                      size="sm"
                    />
                  </CSSTransition>
                </TransitionGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <ProgressBar now={progress} hidden={!isLoadingVat}/>
              </Form.Group>
            </Form.Row>
            <LoaderButton
              // type="submit"
              variant="primary"
              isLoading={isLoadingVat}
              disabled={!validateVat()}
              onClick={handleSearchClientInfoFromVat}
            >
              {t("client.new_multiple.buttons.search")}
            </LoaderButton>
          </Form>
        </Container>
      </Container>
      <Container>
        <TransitionGroup>
          {clientsToCreate.map(
            (client, i) => (
              // i !== 0 ? (
              // <>
              <CSSTransition
                timeout={400}
                classNames="list-transition"
                style={{
                  transition: `opacity ${3 * 75}ms, transform ${3 * 75}ms`,
                }}
                unmountOnExit
                appear
              >
                {/* <div className="list-body"> */}
                <ul className="list">
                  <li>
                    <Fragment key={`${client}~${i}`}>
                      <Container className="shadow p-3 mb-5 bg-themed rounded form">
                        <Form.Row>
                          <Form.Group as={Col} md="6">
                            <TransitionFormLabel delay={2 * 75}>
                              {t("client.update.labels.vat")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={2 * 75}
                              controlId="vat"
                              name="vat"
                              type="text"
                              placeholder={t("client.update.labels.vat")}
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.vat}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <TransitionFormLabel delay={75}>
                              {t("client.new.labels.name")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={75}
                              controlId="name"
                              name="name"
                              type="text"
                              placeholder={t("client.new.labels.name")}
                              componentClass="input"
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.name}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md="12">
                            <TransitionFormLabel delay={3 * 75}>
                              {t("client.new.labels.address")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={3 * 75}
                              controlId="address"
                              name="address"
                              type="text"
                              placeholder={t("client.new.labels.address")}
                              componentClass="input"
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.address}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md="4">
                            <TransitionFormLabel delay={4 * 75}>
                              {t("client.new.labels.city")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={4 * 75}
                              controlId="city"
                              name="city"
                              type="text"
                              placeholder={t("client.new.labels.city")}
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.city}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="4">
                            <TransitionFormLabel delay={5 * 75}>
                              {t("client.new.labels.zip")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={5 * 75}
                              controlId="zip"
                              name="zip"
                              type="text"
                              placeholder={t("client.new.labels.zip")}
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.zip}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="4">
                            <TransitionFormLabel delay={6 * 75}>
                              {t("client.new.labels.country")}
                            </TransitionFormLabel>
                            <TransitionFormControl
                              delay={6 * 75}
                              controlId="country"
                              name="country"
                              type="text"
                              placeholder={t("client.new.labels.country")}
                              componentClass="input"
                              onChange={(event) => handleInputChange(i, event)}
                              value={client.country}
                            />
                          </Form.Group>
                        </Form.Row>
                        <LoaderButton
                          variant="primary"
                          onClick={(event) => handleCreateSingleClient(i)}
                          // isLoading={isDeleting}
                        >
                          {t("generic.buttons.create")}
                        </LoaderButton>{" "}
                        <LoaderButton
                          variant="danger"
                          onClick={(event) => handleRemoveFields(i)}
                          // isLoading={isDeleting}
                        >
                          {t("generic.buttons.delete")}
                        </LoaderButton>
                      </Container>
                    </Fragment>
                  </li>
                </ul>
              </CSSTransition>
            )
            // ) : (
            //   <> </>
            // )
          )}
        </TransitionGroup>
      </Container>
    </>
  );
}
