import React, { useState, useEffect, Fragment } from "react";
import { Form, Col, Button, Spinner, Container, InputGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./NewInvoice.css";
import "./lists.css";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";

export default function NewInvoice(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingInvNum, setIsLoadingInvNum] = useState(true);
  const [name, setName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [clientsSelect, setClientsSelect] = useState("");
  const [client, setClient] = useState("");
  const [autoInvNum, setAutoInvNum] = useState(true);
  const [autoliquidation, setAutoliq] = useState(false);
  const [exp, setExport] = useState(false);
  const [intra, setIntra] = useState(false);
  const [normal, setNormal] = useState(true);
  const { t } = useTranslation();

  const [lines, setLines] = useState([
    {
      description: "",
      cost: "",
      quantity: "",
      vat: "",
    },
  ]);

  const [linesValidation, setLinesValidation] = useState([
    {
      description: {
        invalid: false,
        error: ""
      },
      cost: {
        invalid: false,
        error: ""
      },
      quantity: {
        invalid: false,
        error: ""
      },
      vat: {
        invalid: false,
        error: ""
      },
    },
  ]);

  function getMaxInvoiceNumber() {
    return API.get("invoices", `/max-invoice-number`);
  }

  useEffect(() => {
    async function onLoad() {
      try {
        const tmp = await getMaxInvoiceNumber();
        console.log(tmp);
        setInvoiceNumber(tmp);
        setIsLoadingInvNum(false);

      } catch (e) {
        alert(e);
      }
    }

    onLoad();
    // eslint-disable-next-line
  }, [props.match.params.id]);

  function validateForm() {
    try {
      if (invoiceNumber.length === 0) return false;
      if (isNaN(invoiceNumber)) return false;
      if (name.length === 0) return false;
      if (client.length === 0) return false;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].description.length === 0) return false;
        if (isNaN(lines[i].cost)) return false;
        if (lines[i].cost.length === 0) return false;
        if (isNaN(lines[i].quantity)) return false;
        if (lines[i].quantity.length === 0) return false;
        if (normal) {
          if (isNaN(lines[i].vat)) return false;
          if (lines[i].vat.length === 0) return false;
        }
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  function validateClientsSearch() {
    return name.length > 0;
  }

  async function handleCheck(event) {
    switch (event.target.name) {
      case "autoliquidation":
        setAutoliq(true);
        setExport(false);
        setIntra(false);
        setNormal(false);
        break;
      case "export":
        setAutoliq(false);
        setExport(true);
        setIntra(false);
        setNormal(false);
        break;
      case "intra":
        setAutoliq(false);
        setExport(false);
        setIntra(true);
        setNormal(false);
        break;
      default:
        setAutoliq(false);
        setExport(false);
        setIntra(false);
        setNormal(true);
        break;
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
    try {
      let ret = await createInvoice({
        invoiceNumber,
        client,
        lines,
        autoliquidation,
        export: exp,
        intra,
      });
      console.log(ret);
      // ! FIXME - probably better ways to do this - Does not work in remote -> AccessDenied to the object 
      if (!ret.status) {
        alert(ret.message);
        props.history.push("/settings");
      } else {
        props.history.push("/");
      }
    } catch (e) {
      console.error(e);
      alert(t("invoice.new.alerts.create"));
      props.history.push("/settings");
      
    }
    setIsLoading(false);
  }

  function createInvoice(invoice) {
    return API.post("invoices", "/invoices", {
      body: invoice,
    });
  }

  async function searchClients() {
    setIsLoadingClients(true);
    let result = await getClientFromName(name);
    console.log(result);

    try {
      let items = [];
      if (result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          console.log(result[i]);

          items.push(
            <option value={JSON.stringify(result[i])}>{result[i].name}</option>
          );
        }
        setClient(result[0]);
      } else {
        setIsLoadingClients(false);
        setClient();
        alert(t("invoice.update.alerts.clientNotFound") + '"' + name + '"');
      }
      setClientsSelect(items);
      console.log("items:");
      console.log(items);
    } catch (error) {
      alert("No result");
    }
    setIsLoadingClients(false);
  }
  function getClientFromName(name) {
    // setIsLoadingClients(true);
    let result = API.get("invoices", `/clients_search/${encodeURI(name)}`, {});

    return result;
  }

  async function handleAutoMaxInv() {
    if (autoInvNum === true) {
      setAutoInvNum(false);
      setInvoiceNumber("");
      setIsLoadingInvNum(false);

    } else {
      setAutoInvNum(true);
      setIsLoadingInvNum(true);
      const tmp = await getMaxInvoiceNumber();
      console.log(tmp);
      setInvoiceNumber(tmp);
      setIsLoadingInvNum(false);
    }
  }

  const handleAddFields = () => {
    const values = [...lines];
    values.push({
      description: "",
      cost: "",
      quantity: "",
      vat: "",
    });
    setLines(values);

    const valuesValidation = [...linesValidation];
    valuesValidation.push({
      description: {
        invalid: false,
        error: ""
      },
      cost: {
        invalid: false,
        error: ""
      },
      quantity: {
        invalid: false,
        error: ""
      },
      vat: {
        invalid: false,
        error: ""
      },
    });
    setLinesValidation(valuesValidation);
  };

  const handleRemoveFields = (index) => {
    const values = [...lines];
    values.splice(index, 1);
    setLines(values);
    const valuesValidation = [...linesValidation];
    valuesValidation.splice(index, 1);
    setLinesValidation(valuesValidation);
  };

  const handleInputChange = (index, event) => {
    const values = [...lines];
    if (event.target.name === "description") {
      values[index].description = event.target.value;
    } else if (event.target.name === "cost") {
      values[index].cost = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
    } else {
      values[index].vat = event.target.value;
    }
    setLines(values);


    const valuesValidation = [...linesValidation];
    if (event.target.name === "description") {
      if (values[index].description.length === 0) {
        valuesValidation[index].description.invalid = true;
        valuesValidation[index].description.error = t("invoice.new.controls.error.description.length");
      } else {
        valuesValidation[index].description.invalid = false;
      }
    } else if (event.target.name === "cost") {
      if (values[index].cost.length === 0) {
        valuesValidation[index].cost.invalid = true;
        valuesValidation[index].cost.error = t("invoice.new.controls.error.cost.length");
      } else if (isNaN(values[index].cost)) {
        valuesValidation[index].cost.invalid = true;
        valuesValidation[index].cost.error = t("invoice.new.controls.error.cost.number");
      }
      else {
        valuesValidation[index].cost.invalid = false;
      }
    } else if (event.target.name === "quantity") {
      if (values[index].quantity.length === 0) {
        valuesValidation[index].quantity.invalid = true;
        valuesValidation[index].quantity.error = t("invoice.new.controls.error.quantity.length");
      } else if (isNaN(values[index].quantity)) {
        valuesValidation[index].quantity.invalid = true;
        valuesValidation[index].quantity.error = t("invoice.new.controls.error.quantity.number");
      }
      else {
        valuesValidation[index].quantity.invalid = false;
      }
    } else {
      if (values[index].vat.length === 0) {
        valuesValidation[index].vat.invalid = true;
        valuesValidation[index].vat.error = t("invoice.new.controls.error.vat.length");
      } else if (isNaN(values[index].vat)) {
        valuesValidation[index].vat.invalid = true;
        valuesValidation[index].vat.error = t("invoice.new.controls.error.vat.number");
      }
      else {
        valuesValidation[index].vat.invalid = false;
      }
    }
    setLinesValidation(valuesValidation);
  };

  return (
    <Container className="NewInvoice">
      <h2>{t("invoice.new.labels.header")}</h2>
      <Container className="shadow p-3 mb-5 bg-themed rounded form">
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12" lg="3">
              <TransitionFormLabel delay={75}>
                {t("invoice.new.labels.number")}
              </TransitionFormLabel>
              <TransitionGroup>
                <CSSTransition
                  timeout={400}
                  classNames="list-transition"
                  style={{
                    transition: `opacity ${75}ms, transform ${75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <InputGroup>
                    <Form.Control
                      controlId="name"
                      type="text"
                      placeholder="20XXYYYY"
                      componentClass="input"
                      onChange={(e) =>
                        setInvoiceNumber(e.target.value.replace(",", "."))
                      }
                      value={invoiceNumber}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        {!isLoadingInvNum ? "Auto" : ""}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          hidden={!isLoadingInvNum}
                        ></Spinner>
                      </InputGroup.Text>
                      <InputGroup.Checkbox
                        checked={autoInvNum}
                        label="Auto"
                        onChange={(e) => handleAutoMaxInv()}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </CSSTransition>
              </TransitionGroup>
            </Form.Group>
            {/* <Form.Group as={Col} md="1">
              <Form.Check
                checked={autoInvNum}
                type="switch"
                id="custom-switch"
                label="Auto"
                onChange={(e) => handleAutoMaxInv()}
              />
            </Form.Group> */}
            <Form.Group as={Col} md="6" lg="4" className="d-block d-md-none">
              <TransitionFormLabel delay={2 * 75}>
                {t("invoice.new.labels.name")}
              </TransitionFormLabel>
              <TransitionGroup>
                <CSSTransition
                  timeout={400}
                  classNames="list-transition"
                  style={{
                    transition: `opacity ${2 * 75}ms, transform ${2 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <InputGroup>
                    <Form.Control
                      controlId="name"
                      type="text"
                      placeholder={t("invoice.new.placeholders.name")}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        disabled={!validateClientsSearch()}
                        onClick={searchClients}
                      >
                        <i
                          className="fas fa-arrow-down"
                          aria-hidden="true"
                          hidden={isLoadingClients}
                        ></i>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          hidden={!isLoadingClients}
                        ></Spinner>
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </CSSTransition>
              </TransitionGroup>
            </Form.Group>
            <Form.Group
              as={Col}
              md="6"
              lg="5"
              className="d-none d-md-block d-xl-block"
            >
              <TransitionFormLabel delay={2 * 75}>
                {t("invoice.new.labels.name")}
              </TransitionFormLabel>
              <TransitionGroup>
                <CSSTransition
                  timeout={400}
                  classNames="list-transition"
                  style={{
                    transition: `opacity ${2 * 75}ms, transform ${2 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <InputGroup>
                    <Form.Control
                      controlId="name"
                      type="text"
                      placeholder={t("invoice.new.placeholders.name")}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        disabled={!validateClientsSearch()}
                        onClick={searchClients}
                      >
                        <i
                          className="fas fa-arrow-right"
                          aria-hidden="true"
                          hidden={isLoadingClients}
                        ></i>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          hidden={!isLoadingClients}
                        ></Spinner>
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </CSSTransition>
              </TransitionGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" lg="4">
              <TransitionFormLabel delay={3 * 75}>
                {t("invoice.new.labels.select")}
              </TransitionFormLabel>
              <TransitionFormControl
                delay={3 * 75}
                custom
                as="select"
                controlId="selectName"
                placeholder="Search result"
                onChange={(e) => {
                  // console.log(JSON.parse(e.target.value).name);
                  // console.log(JSON.parse(e.target.value).clientId);
                  setClient(JSON.parse(e.target.value));
                }}
              >
                {clientsSelect}
              </TransitionFormControl>
            </Form.Group>
          </Form.Row>
          <TransitionFormLabel>
            {t("invoice.new.labels.lines")}
          </TransitionFormLabel>
          <TransitionGroup className="form-row form-row-lines">
            {lines.map((line, index) => (
              <CSSTransition
                timeout={400}
                classNames="list-transition"
                style={{
                  transition: `opacity ${(index + 4) * 75}ms, transform ${(index + 4) * 75
                    }ms`,
                }}
                unmountOnExit
                appear
              >
                {/* <div className="list-body"> */}
                <ul className="list">
                  <li>
                    <Fragment key={`${line}~${index}`}>
                      <Form.Row>
                        <Form.Group as={Col} md="6" lg="7">
                          <Form.Control
                            controlId="description"
                            type="text"
                            placeholder={t(
                              "invoice.new.placeholders.lines.description"
                            )}
                            name="description"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            value={line.description}
                            isInvalid={linesValidation[index].description.invalid}
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {linesValidation[index].description.error}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="6" lg="5">
                          <InputGroup md="6" lg="6">
                            <Form.Control
                              controlId="cost"
                              type="text"
                              placeholder="€"
                              name="cost"
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              value={line.cost.replace(",", ".")}
                              isInvalid={linesValidation[index].cost.invalid}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {linesValidation[index].cost.error}
                            </Form.Control.Feedback>
                            <InputGroup.Append>
                              <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control
                              controlId="quantity"
                              type="text"
                              placeholder="10"
                              name="quantity"
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              value={line.quantity.replace(",", ".")}
                              isInvalid={linesValidation[index].quantity.invalid}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {linesValidation[index].quantity.error}
                            </Form.Control.Feedback>
                            <InputGroup.Append>
                              <InputGroup.Text>*</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control
                              controlId="vat"
                              type="text"
                              placeholder="%"
                              name="vat"
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              value={line.vat.replace(",", ".")}
                              disabled={autoliquidation || exp || intra}
                              isInvalid={linesValidation[index].vat.invalid}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {linesValidation[index].vat.error}
                            </Form.Control.Feedback>
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                            <InputGroup.Append>
                              <Button
                                variant="outline-danger"
                                onClick={(event) => handleRemoveFields(index)}
                              >
                                {" "}
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Form.Row>
                    </Fragment>
                  </li>
                </ul>
                {/* </div> */}
              </CSSTransition>
            ))}
          </TransitionGroup>
          <Form.Row>
            <Form.Group as={Col} md="5">
              <Button variant="success" onClick={() => handleAddFields()}>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </Button>{" "}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" lg="2">
              {/* <Form.Group as={Col} md="1" xs="3"> */}
              <TransitionGroup>
                <TransitionFormLabel delay={4 * 75}>
                  {t("invoice.update.labels.VATSpecial")}
                </TransitionFormLabel>
                <CSSTransition
                  timeout={400}
                  classNames="control-form-transition"
                  style={{
                    transition: `opacity ${lines.length + 4 * 75
                      }ms, transform ${lines.length + 4 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <Form.Check
                    type="radio"
                    label={t("invoice.update.labels.normal")}
                    name="normal"
                    onChange={(e) => handleCheck(e)}
                    checked={normal}
                  />
                </CSSTransition>
                <CSSTransition
                  timeout={400}
                  classNames="control-form-transition"
                  style={{
                    transition: `opacity ${lines.length + 4 + 1 * 75
                      }ms, transform ${lines.length + 4 + 1 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <Form.Check
                    type="radio"
                    label={t("invoice.update.labels.autoliquidation")}
                    name="autoliquidation"
                    onChange={(e) => handleCheck(e)}
                    checked={autoliquidation}
                  />
                </CSSTransition>
                <CSSTransition
                  timeout={400}
                  classNames="control-form-transition"
                  style={{
                    transition: `opacity ${lines.length + 4 + 2 * 75
                      }ms, transform ${lines.length + 4 + 2 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <Form.Check
                    type="radio"
                    label={t("invoice.update.labels.export")}
                    name="export"
                    onChange={(e) => handleCheck(e)}
                    checked={exp}
                  />
                </CSSTransition>
                <CSSTransition
                  timeout={400}
                  classNames="control-form-transition"
                  style={{
                    transition: `opacity ${lines.length + 4 + 3 * 75
                      }ms, transform ${lines.length + 4 + 3 * 75}ms`,
                  }}
                  unmountOnExit
                  appear
                >
                  <Form.Check
                    type="radio"
                    label={t("invoice.update.labels.intra")}
                    name="intra"
                    onChange={(e) => handleCheck(e)}
                    checked={intra}
                  />
                </CSSTransition>
              </TransitionGroup>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="5">
              {/* <Button variant="success" onClick={() => handleAddFields()}>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </Button>{" "} */}
              <LoaderButton
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                {t("generic.buttons.create")}
              </LoaderButton>
            </Form.Group>
          </Form.Row>

          {/* <br/>
                <pre>
                {JSON.stringify(lines, null, 2)}
                {client}
                {parseInt(invoiceNumber)}
                {parseFloat(invoiceNumber)}
                </pre> */}
        </Form>
      </Container>
    </Container>
  );
}
