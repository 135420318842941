import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./LoaderButton.css";

type LoaderButtonProps = React.FC<{
    isLoading:boolean
    className?:string
    disabled?:boolean
}>

const LoaderButton:LoaderButtonProps = ({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) => {
    return (
      <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
      >
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          hidden={!isLoading}
        />
        {/* {isLoading } */}
        {/* && <Glyphicon glyph="refresh" className="spinning" />} */}
        {!isLoading ? props.children : null}
      </Button>
    );
}

export default LoaderButton;