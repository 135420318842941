import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import UpdatePassword from "./containers/UpdatePassword";
import AppliedRoute from "./components/AppliedRoute";
import NewInvoice from "./containers/NewInvoice";
import NewClient from "./containers/NewClient";
import NewClients from "./containers/NewClients";
import Clients from "./containers/Clients";
import Invoices from "./containers/Invoices";
import Settings from "./containers/Settings";
// import Settings from "./containers/Settings";
import ClientsHome from "./containers/ClientsHome";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/updatePassword"
        exact
        component={UpdatePassword}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/invoices/new"
        exact
        component={NewInvoice}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/invoices/:id"
        exact
        component={Invoices}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients"
        exact
        component={ClientsHome}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients/new"
        exact
        component={NewClient}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients/new/multiple"
        exact
        component={NewClients}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients/:id"
        exact
        component={Clients}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings"
        exact
        component={Settings}
        appProps={appProps}
      />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
