import React from "react";
import { Alert, Container } from "react-bootstrap";
import "./AlertDismissible.css";

export default function AlertDismissible({
  header,
  text,
  variant,
  onClose,
  ...props
}) {
  return (
    <Container className="AlertDismissible">
      <Alert
        variant={variant}
        onClose={onClose}
        transition="elementType"
        dismissible
        {...props}
      >
        <Alert.Heading>{header}</Alert.Heading>
        <p>{text}</p>
      </Alert>
    </Container>
  );
}
