const prodConfig = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    // BUCKET: "invoices-app-api-dev-attachmentsbucket-140ryok2m6ynb",
    BUCKET: "invoices-app-api-prod-attachmentsbucket-1619a6w4tt4e",
  },
  apiGateway: {
    REGION: "eu-west-1",
    // URL: "https://uyp1noqct5.execute-api.eu-west-1.amazonaws.com/dev",
    URL: "https://0neepveyt9.execute-api.eu-west-1.amazonaws.com/prod",
    // URL: "http://localhost:3000/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    // USER_POOL_ID: "eu-west-1_1kx05X0KD",
    // APP_CLIENT_ID: "400uotsgljtng2e7bpf884b4tk",
    // IDENTITY_POOL_ID: "eu-west-1:2f07a005-f81a-4be7-8dac-816129067db8"
    USER_POOL_ID: "eu-west-1_Ikc1CeHpD",
    APP_CLIENT_ID: "6mkg4dh75q496k0t14hu2rh4kq",
    IDENTITY_POOL_ID: "eu-west-1:4919b71b-c05d-4370-b5b9-754c7bfee5fc",
  },
};

const devConfig = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "invoices-app-api-dev-attachmentsbucket-140ryok2m6ynb",
    // BUCKET: "invoices-app-api-prod-attachmentsbucket-1619a6w4tt4e",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://uyp1noqct5.execute-api.eu-west-1.amazonaws.com/dev",
    // URL: "https://0neepveyt9.execute-api.eu-west-1.amazonaws.com/prod",
    // URL: "http://localhost:3000/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_1kx05X0KD",
    APP_CLIENT_ID: "400uotsgljtng2e7bpf884b4tk",
    IDENTITY_POOL_ID: "eu-west-1:2f07a005-f81a-4be7-8dac-816129067db8",
    // USER_POOL_ID: "eu-west-1_Ikc1CeHpD",
    // APP_CLIENT_ID: "6mkg4dh75q496k0t14hu2rh4kq",
    // IDENTITY_POOL_ID: "eu-west-1:4919b71b-c05d-4370-b5b9-754c7bfee5fc",
  },
};

const localConfig = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "invoices-app-api-dev-attachmentsbucket-140ryok2m6ynb",
    // BUCKET: "invoices-app-api-prod-attachmentsbucket-1619a6w4tt4e",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "http://localhost:3000/dev",
    // URL: "https://0neepveyt9.execute-api.eu-west-1.amazonaws.com/prod",
    // URL: "http://localhost:3000/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_1kx05X0KD",
    APP_CLIENT_ID: "400uotsgljtng2e7bpf884b4tk",
    IDENTITY_POOL_ID: "eu-west-1:2f07a005-f81a-4be7-8dac-816129067db8",
    // USER_POOL_ID: "eu-west-1_Ikc1CeHpD",
    // APP_CLIENT_ID: "6mkg4dh75q496k0t14hu2rh4kq",
    // IDENTITY_POOL_ID: "eu-west-1:4919b71b-c05d-4370-b5b9-754c7bfee5fc",
  },
};

// process.env.NODE_ENV === "production" ? prodConfig : devConfig;

let config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;
if (process.env.REACT_APP_ENV) {
  // config = process.env.REACT_APP_ENV === "production" ? prodConfig : devConfig;
  if (process.env.REACT_APP_ENV === "local") {
    config = localConfig;
  }
  if (process.env.REACT_APP_ENV === "dev") {
    config = devConfig;
  }
  if (process.env.REACT_APP_ENV === "production") {
    config = prodConfig;
  }
}
// console.log("NODE_ENV:", process.env.NODE_ENV);
// console.log("REACT_APP_ENV:", process.env.REACT_APP_ENV);
// console.log(config);

// const config =
// const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

export default config;
