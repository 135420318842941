import React from "react";
import {
  Form,
  Col,
  Row,
  Alert,
} from "react-bootstrap";
import Emoji from "../components/Emoji";

const renderEmptyListAlert: React.FC<{ header:string; text:string }> = ({
  header,
  text,
}: {
  header: string;
  text: string;
}) => {
  return (
    <Row>
      <Form.Group as={Col} md="12">
        <Alert variant="primary">
          <Alert.Heading>{header}</Alert.Heading>
          <p>
            {text}
            <Emoji symbol="↗️" />
          </p>
        </Alert>
      </Form.Group>
    </Row>
  );
};

export default renderEmptyListAlert;