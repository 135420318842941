import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Form, Fade, Container } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AlertDismissible from "../components/AlertDismissible";
import { useFormFields } from "../libs/hooksLib";
import TransitionFormLabel from "../components/TransitionFormLabel";
import TransitionFormControl from "../components/TransitionFormControl";



export default function UpdatePassword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // const [forgotVue, setForgotVue] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertVariant, setAlertVariant] = useState("");

  const [fields, handleFieldChange] = useFormFields({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  // const [newFields, handleNewFieldChange] = useFormFields({
  //   email: fields.email,
  //   code: "",
  //   newPassword: "",
  //   newPasswordConfirm: "",
  // });

  function validateForm() {
    if (fields.currentPassword === 0) return false;
    if (fields.newPassword.length === 0) return false;
    if (fields.newPasswordConfirm.length === 0) return false;
    if (fields.newPassword === fields.newPasswordConfirm) return true;
  }

  // function validateFormForgot() {
  //   if (newFields.code.length === 0) return false;
  //   if (newFields.newPassword.length === 0) return false;
  //   if (newFields.newPasswordConfirm.length === 0) return false;
  //   return true;
  // }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
        // alert("WIP");
        // console.log(await Auth.currentAuthenticatedUser());
        // console.log(Auth);
        // Auth.currentSession().then((res) => {
        //   let accessToken = res.getAccessToken();
        //   let jwt = accessToken.getJwtToken();
        //   //You can print them to see the full objects
        //   console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
        //   console.log(`myJwt: ${jwt}`);
        // });

        const curUser = await Auth.currentAuthenticatedUser();
        // console.log(curUser);
        // console.log(fields.currentPassword);
        // console.log(fields.newPasswordConfirm);
        const pass_update_ret = await Auth.changePassword(
          curUser,
          fields.currentPassword,
          fields.newPasswordConfirm
        );
        console.log(pass_update_ret);

        if (pass_update_ret === 'SUCCESS') props.history.push("/");
        else{
          setAlertHeader("Oops, an error happened");
          setAlertText(
            `You probably mistyped your credentials, please try again! ${pass_update_ret}`
          );
          setAlertVariant("danger");
          setShowAlert(true);
          setIsLoading(false);
        }


        // await Auth.signOut();

        // userHasAuthenticated(false);

        // props.history.push("/login");
    } catch (e) {
      setAlertHeader("Oops, an error happened");
      setAlertText("You probably mistyped your credentials, please try again!");
      setAlertVariant("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  }

  // async function handleSubmitForgot(event) {
  //   event.preventDefault();

  //   setIsLoading(true);

  //   try {
  //     await Auth.forgotPasswordSubmit(
  //       fields.email,
  //       newFields.code,
  //       newFields.newPassword
  //     )
  //       .then((data) => console.log(data))
  //       .catch((err) => console.log(err));
  //     await Auth.signIn(fields.email, newFields.newPassword);
  //     props.userHasAuthenticated(true);
  //     // props.history.push("/");
  //   } catch (err) {
  //     setAlertHeader("Oops, an error happened");
  //     setAlertText(err.message);
  //     setAlertVariant("danger");
  //     setShowAlert(true);
  //     setIsLoading(false);
  //     console.error(err);
  //   }
  // }

  // async function handleForgotPassword(event) {
  //   event.preventDefault();

  //   setIsLoading(true);

  //   try {
  //     await Auth.forgotPassword(fields.email)
  //       .then((data) => {
  //         console.log(data);

  //         setForgotVue(true);
  //       })
  //       .catch((err) => {
  //         // Alert("An error happened!", "The email can not be empty. please fill your email address.", "danger")
  //         setAlertHeader("Oops, an error happened");
  //         setAlertText(err.message);
  //         setAlertVariant("danger");
  //         setShowAlert(true);
  //         console.log(err);
  //       });
  //   } catch (e) {
  //     setAlertHeader("Oops, an error happened");
  //     setAlertText(e.message);
  //     setAlertVariant("danger");
  //     setShowAlert(true);
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  // }

  function renderLogin() {
    return (
      <Container>
        <Container>
          <Fade in={showAlert} unmountOnExit="true" mountOnEnter="true">
            <AlertDismissible
              header={alertHeader}
              text={alertText}
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
            />
          </Fade>
        </Container>
        <Container className="Login">
          <form onSubmit={handleSubmit}>
            <Form.Group controlId="currentPassword">
              <TransitionFormLabel delay={75}>
                Current password
              </TransitionFormLabel>
              <TransitionFormControl
                delay={75}
                type="password"
                placeholder="Password"
                value={fields.currentPassword}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <TransitionFormLabel delay={2 * 75}>
                New password
              </TransitionFormLabel>
              <TransitionFormControl
                delay={2 * 75}
                type="password"
                placeholder="Password"
                value={fields.newPassword}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="newPasswordConfirm">
              <TransitionFormLabel delay={3 * 75}>
                New password again
              </TransitionFormLabel>
              <TransitionFormControl
                delay={3 * 75}
                type="password"
                placeholder="Password"
                value={fields.newPasswordConfirm}
                onChange={handleFieldChange}
              />
            </Form.Group>
            {/* <LoaderButton
              block
              variant="link"
              onClick={handleForgotPassword}
              isLoading={isLoading}
            >
              Forgot password ?
            </LoaderButton> */}
            <LoaderButton
              block
              type="submit"
              bsSize="large"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Update
            </LoaderButton>
          </form>
        </Container>
      </Container>
    );
  }

  // function renderForgotPassword() {
  //   return (
  //     <Container>
  //       <Fade in={showAlert} unmountOnExit="true" mountOnEnter="true">
  //         <AlertDismissible
  //           header={alertHeader}
  //           text={alertText}
  //           variant={alertVariant}
  //           onClose={() => setShowAlert(false)}
  //         />
  //       </Fade>
  //       <Container className="Login">
  //         <form onSubmit={handleSubmitForgot}>
  //           <Form.Group controlId="email" bsSize="large">
  //             <Form.Label>Email</Form.Label>
  //             <Form.Control
  //               autoFocus
  //               type="email"
  //               placeholder="Email"
  //               value={fields.email}
  //               disabled="true"
  //               // onChange={handleFieldChange}
  //             />
  //           </Form.Group>
  //           <Form.Group controlId="code" bsSize="large">
  //             <Form.Label>Confirmation code</Form.Label>
  //             <Form.Control
  //               autoFocus
  //               type="text"
  //               placeholder="Code received via email"
  //               value={newFields.code}
  //               onChange={handleNewFieldChange}
  //             />
  //           </Form.Group>
  //           <Form.Group controlId="newPassword" bsSize="large">
  //             <Form.Label>New password</Form.Label>
  //             <Form.Control
  //               type="password"
  //               placeholder="Password"
  //               value={newFields.newPassword}
  //               onChange={handleNewFieldChange}
  //             />
  //           </Form.Group>
  //           <Form.Group controlId="newPasswordConfirm" bsSize="large">
  //             <Form.Label>Confirm new password</Form.Label>
  //             <Form.Control
  //               type="password"
  //               placeholder="Password"
  //               value={newFields.newPasswordConfirm}
  //               onChange={handleNewFieldChange}
  //             />
  //           </Form.Group>
  //           <LoaderButton
  //             block
  //             type="submit"
  //             bsSize="large"
  //             isLoading={isLoading}
  //             disabled={!validateFormForgot()}
  //           >
  //             Update
  //           </LoaderButton>
  //         </form>
  //       </Container>
  //     </Container>
  //   );
  // }

  return (
    <Container>
      {renderLogin()}
      {/* {forgotVue === false ? renderLogin() : renderForgotPassword()} */}
    </Container>
  );
}
