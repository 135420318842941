import { Client } from "../types/Client";

export function getClientInfoBrief(client:Client):string {
  return (
    client.address +
    ", " +
    client.zip +
    ", " +
    client.city +
    ", " +
    client.country
  );
}
