import { useState } from "react";

type Dictionary<T> = {
    [Key: string]: T;
}

type Event = {
  target: {
    id: string
    value: string
  }
}

export function useFormFields(initialState:  Dictionary<string>) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event:Event) {      
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}
