import React, { useState, useEffect } from "react";
import "./Home.css";
import "./lists.css";
import { API } from "aws-amplify";
import {
  Spinner,
  Alert,
  Row,
  Col,
  Form,
  Container,
  Card,
  Button,
  Fade,
  InputGroup,
} from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Emoji from "../components/Emoji";
import * as invoiceLib from "../libs/invoicesLib";
import renderEmptyListAlert from "../libs/emptyListLib";
import { useTranslation } from "react-i18next";
// import i18n from "../lang/i18n";
// import loadLanguage from "../libs/languagesLib";

export default function Home(props) {
  const { t } = useTranslation();
  
  // console.log(lang);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState("");

  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  

  useEffect(() => {
    async function onLoad() {
      // loadLanguage();
      if (!props.isAuthenticated) {
        return;
      }

      setIsLoading(true);
      try {
        const invoices = await loadInvoices();
        setInvoices(invoices);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadInvoices() {
    return API.get("invoices", "/invoices");
  }

  function loadInvoicesFiltered(invoiceNumber) {
    return API.get(
      "invoices",
      `/invoices/filtered/${encodeURI(invoiceNumber)}`
    );
  }

  async function handleFilter(event) {
    event.preventDefault();

    setIsSearching(true);
    const ret = await loadInvoicesFiltered(filterInvoiceNumber);
    // console.log(ret);
    setInvoices(ret);
    setIsSearching(false);
  }

  async function handleButtonFilter() {
    if (isFiltering) {
      setIsFiltering(false);
      const ret = await loadInvoices();
      setInvoices(ret);
    } else setIsFiltering(true);
  }

  function validateFilter() {
    if (filterInvoiceNumber.length > 0) return true;
    return false;
  }

  // function getObject(obj){
  //   if (typeof obj ==! "undefined"){
  //     return obj;
  //   }else{
  //     return false;
  //   }
  // }

  async function handleDelete(event, invoiceId) {
    event.preventDefault();

    const confirmed = window.confirm(
      t("invoice.home.alerts.delete")
    );

    if (!confirmed) {
      return;
    }
    setIsDeleting(true);

    try {
      await API.del("invoices", `/invoices/${invoiceId}`);
      const invoices = await loadInvoices();
      setInvoices(invoices);
    } catch (e) {
      alert(e);
    }
    setIsDeleting(false);
  }

  function renderInvoicesListItem(invoice, i) {
    let clientName;
    
    if (typeof invoice.client !== "undefined") {
      if (typeof invoice.client.name !== "undefined") {
        clientName = invoice.client.name;
      } else {
        console.log(typeof invoice.client.name);
        console.log("invoice.client.name -> undefined");
      }
    } else {
      console.log(typeof invoice.client);
      console.log("invoice.client -> undefined");
    }
    return (
      // <Container>
      // <Container
      //   key={invoice}
      //   className="listInvoices"
      // >
      <Card key={invoice} className="listInvoices shadow">
        <Card.Header>
          {t("invoice.home.labels.number") + invoice.invoiceNumber}
        </Card.Header>
        <Card.Body>
          <Card.Title>
            {t("invoice.home.labels.to") +
              clientName +
              t("invoice.home.labels.created") +
              new Date(invoice.createdAt).toLocaleString()}
          </Card.Title>
          <Card.Text>
            {/* {JSON.stringify(invoice.lines)} */}
            {invoiceLib.getLinesBrief({
              lines: invoice.lines,
              total: invoice.total,
            })}
          </Card.Text>
          <LinkContainer to={"/invoices/" + invoice.invoiceId}>
            <Button variant="primary">{t("generic.buttons.update")}</Button>
          </LinkContainer>{" "}
          <LoaderButton
            isLoading={isDeleting}
            variant="danger"
            onClick={(e) => handleDelete(e, invoice.invoiceId)}
          >
            {t("generic.buttons.delete")}
          </LoaderButton>
          <LoaderButton
            variant="link"
            onClick={(e) => invoiceLib.handleDownload(invoice.invoiceId)}
          >
            {t("generic.buttons.download")}
          </LoaderButton>
        </Card.Body>
      </Card>
      // </Container>
      // </Container>
    );
  }

  function renderInvoicesList(invoices) {
    if (invoices.length === 0){
      return (
        <>
          {renderEmptyListAlert({
            header: t("invoice.home.empty.header"),
            text: t("invoice.home.empty.text"),
          })}
        </>
      );
    }
    return (
      <TransitionGroup>
        {[{}].concat(invoices).map((invoice, i) =>
          i !== 0 ? (
            <CSSTransition
              //in={this.state.showList}
              timeout={400}
              classNames="list-transition"
              style={{
                transition: `opacity ${i * 75}ms, transform ${
                  i * 75
                }ms`,
              }}
              unmountOnExit
              appear
              //onEntered={this.listSwitch}
              //onExit={this.listSwitch}
            >
              <div className="list-body">
                <ul className="list">
                  <li
                  // className={cx("list-item", {
                  //   "list-item--active": this.state.highlightedHobby,
                  // })}
                  >
                    {renderInvoicesListItem(invoice)}
                    {/* {invoice.client.name} */}
                  </li>
                </ul>
              </div>
            </CSSTransition>
          ) : (
            <></>
          )
        )}
      </TransitionGroup>
    );
  }

  function renderLander() {
    return (
      <Row>
        <Form.Group as={Col} md="12">
          <Alert variant="primary">
            <Alert.Heading>Hey, nice to see you!</Alert.Heading>
            <p>
              Please use the login button to start building
              <Emoji symbol="↗️" />
            </p>
          </Alert>
        </Form.Group>
      </Row>
    );
  }

  function renderInvoices() {
    return (
      <Container className="invoices">
        <Form.Row>
          <Form.Group as={Col} lg="8" md="8" xs="8">
            <h2 className="header">{t("invoice.home.labels.header")}</h2>
          </Form.Group>
          <Form.Group as={Col} lg="4" md="4" xs="4">
            <LinkContainer key="new" to="/invoices/new">
              <LoaderButton
                className="float-right"
                isLoading={isLoading}
                variant="outline-primary"
              >
                <i className="fa fa-plus"></i>
              </LoaderButton>
            </LinkContainer>
            <LoaderButton
              style={{ marginRight: ".25em" }}
              className="float-right"
              onClick={handleButtonFilter}
              variant="outline-primary"
            >
              <i className="fa fa-filter"></i>
            </LoaderButton>
          </Form.Group>
        </Form.Row>
        <Form onSubmit={handleFilter}>
          <Form.Row>
            <Fade in={isFiltering} unmountOnExit="true" mountOnEnter="true">
              <Form.Group as={Col} md="12">
                <InputGroup>
                  <Form.Control
                    controlId="invoiceNumber"
                    type="text"
                    placeholder={t("invoice.home.placeholders.filter")}
                    onChange={(e) => setFilterInvoiceNumber(e.target.value)}
                    value={filterInvoiceNumber}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="primary"
                      disabled={!validateFilter()}
                      // onClick={handleFilter}
                      type="submit"
                    >
                      {!isSearching ? t("invoice.home.buttons.filter") : ""}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        hidden={!isSearching}
                      ></Spinner>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Fade>
          </Form.Row>
        </Form>
        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        {!isLoading && renderInvoicesList(invoices)}
      </Container>
    );
  }

  return (
    <Container className="Home">
      {props.isAuthenticated ? renderInvoices() : renderLander()}
    </Container>
  );
}
